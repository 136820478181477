<template>
    <div class="wrapper">
        <!-- 混凝土生产成本 -->
        <table class="custom-table">
            <tbody>
                <tr>
                    <td class="custom-th custom-th-bg" rowspan="2" colspan="3">
                        一、直接成本
                    </td>
                    <td class="custom-th">
                        总用量（吨）
                    </td>
                    <td class="custom-th">
                        总金额（元）
                    </td>
                    <td class="custom-th">
                        容重（吨/方）
                    </td>
                    <td class="custom-th">
                        单位金额（元/方）
                    </td>
                </tr>
                <tr>
                    <td :class="{'red': parseFloat(data.costs.direct_weight)<0}">
                        {{ data.costs.direct_weight }}
                    </td>
                    <td :class="{'red': parseFloat(data.costs.direct_money)<0}">
                        {{ data.costs.direct_money }}
                    </td>
                    <td :class="{'red': parseFloat(data.costs.direct_unitconsumption)<0}">
                        {{ data.costs.direct_unitconsumption }}
                    </td>
                    <td :class="{'red': parseFloat(data.costs.direct_settlementprice)<0}">
                        {{ data.costs.direct_settlementprice }}
                    </td>
                </tr>
                <tr>
                    <td colspan="3"></td>
                    <td class="custom-th">
                        总用量（吨）
                    </td>
                    <td class="custom-th">
                        总金额（元）
                    </td>
                    <td class="custom-th">
                        单耗（吨/方）
                    </td>
                    <td class="custom-th">
                        结算价格（元/吨）
                    </td>
                </tr>
                <template v-for="(direct,directIndex) in data.costsDirects">
                    <tr :key="directIndex" v-if="direct.costsDirectDetails.length>0">
                        <td style="width:.5rem;"></td>
                        <td colspan="2" class="custom-th">
                            {{ direct.costsDirect.type_name }}
                        </td>
                        <td :class="{'red': parseFloat(direct.costsDirect.weight)<0}">
                            {{ direct.costsDirect.weight }}
                        </td>
                        <td :class="{'red': parseFloat(direct.costsDirect.money)<0}">
                            {{ direct.costsDirect.money }}
                        </td>
                        <td :class="{'red': parseFloat(direct.costsDirect.unitconsumption)<0}">
                            {{ direct.costsDirect.unitconsumption }}
                        </td>
                        <td :class="{'red': parseFloat(direct.costsDirect.settlementprice)<0}">
                            {{ direct.costsDirect.settlementprice }}
                        </td>
                    </tr>
                    <tr :key="directIndex" v-if="direct.costsDirectDetails.length<=0" :class="{'border6': directIndex<data.costsDirects.length-1}">
                        <td style="width:.5rem;"></td>
                        <td colspan="2" class="custom-th">
                            {{ direct.costsDirect.type_name }}
                        </td>
                        <td>
                            <el-input
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :disabled="type=='approval'"
                                v-model="direct.costsDirect.weight"
                                @blur="computDirect2('costsDirect',null,direct.costsDirect,directIndex, direct.costsDirect.weight)"
                                :class="{'red': parseFloat(direct.costsDirect.weight)<0}"
                            ></el-input>
                        </td>
                        <td>
                            <el-input
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :disabled="type=='approval'"
                                v-model="direct.costsDirect.money"
                                @blur="computDirect2('costsDirect',null,direct.costsDirect,directIndex, direct.costsDirect.money)"
                                :class="{'red': parseFloat(direct.costsDirect.money)<0}"
                            ></el-input>
                        </td>
                        <td :class="{'red': parseFloat(direct.costsDirect.unitconsumption)<0}">
                            {{ direct.costsDirect.unitconsumption }}
                        </td>
                        <td :class="{'red': parseFloat(direct.costsDirect.settlementprice)<0}">
                            {{ direct.costsDirect.settlementprice }}
                        </td>
                    </tr>
                    <template v-for="(v, vIndex) in direct.costsDirectDetails">
                        <tr :key="v.rcdd_id" :class="{'border6':vIndex===direct.costsDirectDetails.length-1}">
                            <td></td>
                            <td style="width:.5rem;"></td>
                            <td class="custom-th">
                                {{ v.name }}
                            </td>
                            <td>
                                <el-input
                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                    :disabled="type=='approval'"
                                    v-model="v.weight"
                                    @blur="computDirect('detail',v,direct.costsDirect,directIndex, v.weight)"
                                    :class="{'red': parseFloat(v.weight)<0}"
                                ></el-input>
                            </td>
                            <td>
                                <el-input
                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                    :disabled="type=='approval'"
                                    v-model="v.money"
                                    @blur="computDirect('detail',v,direct.costsDirect,directIndex, v.money)"
                                    :class="{'red': parseFloat(v.money)<0}"
                                ></el-input>
                            </td>
                            <td :class="{'red': parseFloat(v.unitconsumption)<0}">
                                {{ v.unitconsumption }}
                            </td>
                            <td :class="{'red': parseFloat(v.settlementprice)<0}">
                                {{ v.settlementprice }}
                            </td>
                        </tr>
                    </template>
                </template>
                <tr>
                    <td class="custom-th custom-th-bg border6" rowspan="2" colspan="3">
                        二、水
                    </td>
                    <td class="custom-th">
                        总用量（吨）
                    </td>
                    <td class="custom-th">
                        总金额（元）
                    </td>
                    <td class="custom-th">
                        单耗（吨/方）
                    </td>
                    <td class="custom-th">
                        结算价格（元/吨）
                    </td>
                </tr>
                <tr class="border6">
                    <td>
                        <el-input
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :disabled="type=='approval'"
                            v-model="data.costs.water_weight"
                            @blur="computWater(data.costs.water_weight)"
                            :class="{'red': parseFloat(data.costs.water_weight)<0}"
                        ></el-input>
                    </td>
                    <td>
                        <el-input
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :disabled="type=='approval'"
                            v-model="data.costs.water_money"
                            @blur="computWater(data.costs.water_money)"
                            :class="{'red': parseFloat(data.costs.water_money)<0}"
                        ></el-input>
                    </td>
                    <td :class="{'red': parseFloat(data.costs.water_unitconsumption)<0}">
                        {{ data.costs.water_unitconsumption }}
                    </td>
                    <td :class="{'red': parseFloat(data.costs.water_settlementprice)<0}">
                        {{ data.costs.water_settlementprice }}
                    </td>
                </tr>
                <tr>
                    <td class="custom-th custom-th-bg border6" rowspan="2" colspan="3">
                        三、电力
                    </td>
                    <td class="custom-th">
                        总用量（kwh）
                    </td>
                    <td class="custom-th">
                        总金额（元）
                    </td>
                    <td class="custom-th">
                        单耗（kwh/方）
                    </td>
                    <td class="custom-th">
                        结算价格（元/kwh）
                    </td>
                </tr>
                <tr class="border6">
                    <td>
                        <el-input
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :disabled="type=='approval'"
                            v-model="data.costs.power_weight"
                            @blur="computPower(data.costs.power_weight)"
                            :class="{'red': parseFloat(data.costs.power_weight)<0}"
                        ></el-input>
                    </td>
                    <td>
                        <el-input
                            oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                            :disabled="type=='approval'"
                            v-model="data.costs.power_money"
                            @blur="computPower(data.costs.power_money)"
                            :class="{'red': parseFloat(data.costs.power_money)<0}"
                        ></el-input>
                    </td>
                    <td :class="{'red': parseFloat(data.costs.power_unitconsumption)<0}">
                        {{ data.costs.power_unitconsumption }}
                    </td>
                    <td :class="{'red': parseFloat(data.costs.power_settlementprice)<0}">
                        {{ data.costs.power_settlementprice }}
                    </td>
                </tr>
                <tr>
                    <td class="custom-th custom-th-bg" rowspan="2" colspan="3">
                        四、直接人工
                    </td>
                    <td colspan="2" class="custom-th">
                        总金额（元）
                    </td>
                    <td colspan="2" class="custom-th">
                        单位金额（元/方）
                    </td>
                </tr>
                <tr>
                    <td colspan="2" :class="{'red': parseFloat(data.costs.person_money)<0}">
                        {{ data.costs.person_money }}
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.costs.person_price)<0}">
                        {{ data.costs.person_price }}
                    </td>
                </tr>
                <template v-for="(item, costsPeoplesIndex) in data.costsPeoples">
                    <tr :key="item.rcp_id" :class="{'border6': costsPeoplesIndex===data.costsPeoples.length-1}">
                        <td></td>
                        <td colspan="2" class="custom-th">
                            {{ item.name }}
                        </td>
                        <td colspan="2">
                            <el-input
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :disabled="type=='approval'"
                                v-model="item.money"
                                @blur="computPeoples(item,item.money)"
                                :class="{'red': parseFloat(item.money)<0}"
                            ></el-input>
                        </td>
                        <td colspan="2" :class="{'red': parseFloat(item.price)<0}">
                            {{ item.price }}
                        </td>
                    </tr>
                </template>
                <tr>
                    <td class="custom-th custom-th-bg" rowspan="2" colspan="3">
                        五、制造费用
                    </td>
                    <td colspan="2" class="custom-th">
                        总金额（元）
                    </td>
                    <td colspan="2" class="custom-th">
                        单位金额（元/方）
                    </td>
                </tr>
                <tr>
                    <td colspan="2" :class="{'red': parseFloat(data.costs.produce_meney)<0}">
                        {{ data.costs.produce_meney }}
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.costs.produce_price)<0}">
                        {{ data.costs.produce_price }}
                    </td>
                </tr>
                <template v-for="item in data.costsProduces">
                    <tr :key="item.rcp_id">
                        <td></td>
                        <td colspan="2" class="custom-th">
                            {{ item.name }}
                        </td>
                        <td colspan="2">
                            <el-input
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :disabled="type=='approval'"
                                v-model="item.money"
                                @blur="computProduce(item, item.money)"
                                :class="{'red': parseFloat(item.money)<0}"
                            ></el-input>
                        </td>
                        <td colspan="2" :class="{'red': parseFloat(item.price)<0}">
                            {{ item.price }}
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
/* eslint-disable */
import util from '@/utils/util';
export default {
    components: {},
    props: {
        reportMonth: {
            type: [Object],
        },
        type: {
            type: [String],
        },
        loadObj: {
            type: [Object],
        },
    },
    data() {
        return {
            data: {
                costs: {},
                costsDirects: [],
                costsPeoples: [],
                costsProduces: [],
            },
            oldData: {},
            monthSales: null,
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 获取-生产成本
        getCosts() {
            this.$axios
                .get('/interfaceApi/report/costs/' + this.reportMonth.report_id)
                .then(res => {
                    if (res) {
                        this.data = res;
                        this.oldData = this.oldData = JSON.parse(JSON.stringify(this.data));
                        // this.loadObj.close();
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存-生产成本
        save() {
            const json = this.filterData();
            const newData = json.parseData;
            if (!newData.costs&&newData.costsDirects.length<1&&newData.costsDirectDetails.length<1&&newData.costsPeoples.length<1&&newData.costsProduces.length<1) {
                this.$message.warning('暂无修改内容！');
                this.$emit('save-end', 'sales', 'validateFailed');
                return;
            }
            if (!json.isExistMinus) {
                this.$axios
                .post('/interfaceApi/report/costs/save', newData)
                .then(res => {
                    if (res) {
                        if (res.costs) {
                            this.data.costs = res.costs;
                        }
                        if (res.costsDirects.length > 0) {
                            res.costsDirects.map(item => {
                                this.data.costsDirects = this.data.costsDirects.map(v => {
                                    if (v.costsDirect.type_code === item.type_code) {
                                        v.costsDirect = item;
                                    }
                                    return v;
                                });
                            });
                        }
                        if (res.costsDirectDetails.length > 0) {
                            res.costsDirectDetails.map(item => {
                                this.data.costsDirects = this.data.costsDirects.map(v => {
                                    v.costsDirectDetails = v.costsDirectDetails.map(detailItem => {
                                        if (detailItem.code === item.code) {
                                            detailItem = item;
                                        }
                                        return detailItem;
                                    });
                                    return v;
                                });
                            });
                        }
                        if (res.costsPeoples) {
                            res.costsPeoples.map(item => {
                                this.data.costsPeoples = this.data.costsPeoples.map(v => {
                                    if (v.sort_num === item.sort_num) {
                                        v = item;
                                    }
                                    return v;
                                });
                            });
                        }
                        if (res.costsProduces) {
                            res.costsProduces.map(item => {
                                this.data.costsProduces = this.data.costsProduces.map(v => {
                                    if (v.sort_num === item.sort_num) {
                                        v = item;
                                    }
                                    return v;
                                });
                            });
                        }
                        this.$message.success('保存成功');
                        this.$emit('save-end', 'costs', 'success');
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                    this.$emit('save-end', 'sales', 'error');
                });
            } else {
                this.$message.warning('存在负数，请重新填写！');
                this.$emit('save-end', 'sales', 'validateFailed');
            }
            
        },
        // 过滤数据 null的转换成0,并判断是否存在负数
        // eslint-disable-next-line complexity
        filterData() {
            const newData = {
                costs: null,
                costsDirects: [],
                costsDirectDetails: [],
                costsPeoples: [],
                costsProduces: [],
            };
            const _this = this;
            const costsB = util.Compare(this.oldData.costs, this.data.costs);
            if (!costsB) {
                newData.costs = this.data.costs;
            }

            this.oldData.costsDirects.map((item, index) => {
                const costsDirectB = util.Compare(item.costsDirect, _this.data.costsDirects[index].costsDirect);
                if (!costsDirectB) {
                    newData.costsDirects.push(_this.data.costsDirects[index].costsDirect);
                }
                item.costsDirectDetails.map((v, vIndex) => {
                    const costsDirectDetailsB = util.Compare(v, _this.data.costsDirects[index].costsDirectDetails[vIndex]);
                    if (!costsDirectDetailsB) {
                        newData.costsDirectDetails.push(_this.data.costsDirects[index].costsDirectDetails[vIndex]);
                    }
                });
            });

            this.oldData.costsPeoples.map((item, index) => {
                const costsPeoplesB = util.Compare(item, _this.data.costsPeoples[index]);
                if (!costsPeoplesB) {
                    newData.costsPeoples.push(_this.data.costsPeoples[index]);
                }
            });

            this.oldData.costsProduces.map((item, index) => {
                const costsProducesB = util.Compare(item, _this.data.costsProduces[index]);
                if (!costsProducesB) {
                    newData.costsProduces.push(_this.data.costsProduces[index]);
                }
            });
            const json = { parseData: newData, isExistMinus: false };
            const costsFalse = false;
            if(newData.costs) {
                for (const key in newData.costs) {
                    let notParse = false;
                    if (key === 'rc_id' || key === 'report_id') {
                        notParse = true;
                    }
                    if (!notParse) {
                        if (!newData.costs[key]) {
                            newData.costs[key] = 0;
                        } else {
                            newData.costs[key] = parseFloat(newData.costs[key]);
                        }
                        if (newData.costs[key] < 0) {
                            costsFalse = true;
                        }
                    }
                }
            }
            const costsDirectsFalse = false;
            if (newData.costsDirects.length>0) {
                newData.costsDirects = newData.costsDirects.map(item=>{
                    for (const key in item) {
                        let notParse = false;
                        if (key === 'rcd_id' || key === 'report_id'||key==='type_code'||key==='type_name'||key==='sort_num') {
                            notParse = true;
                        }
                        if (!notParse) {
                            if (!item[key]) {
                                item[key] = 0;
                            } else {
                                item[key] = parseFloat(item[key]);
                            }
                            if (item[key] < 0) {
                                costsDirectsFalse = true;
                            }
                        }
                    }
                    return item;
                });
            }
            const costsDirectDetailsFalse = false;
            if (newData.costsDirectDetails.length>0) {
                newData.costsDirectDetails = newData.costsDirectDetails.map(item=>{
                    for (const key in item) {
                        let notParse = false;
                        if (key === 'rcdd_id' || key === 'report_id'||key==='direct_type'||key==='code'||key==='name'||key==='sort_num') {
                            notParse = true;
                        }
                        if (!notParse) {
                            if (!item[key]) {
                                item[key] = 0;
                            } else {
                                item[key] = parseFloat(item[key]);
                            }
                            if (item[key] < 0) {
                                costsDirectDetailsFalse = true;
                            }
                        }
                    }
                    return item;
                });
            }
            const costsPeoplesFalse = false;
            if (newData.costsPeoples.length>0) {
                newData.costsPeoples = newData.costsPeoples.map(item=>{
                    for (const key in item) {
                        let notParse = false;
                        if (key === 'rcp_id' || key === 'report_id'||key==='name'||key==='sort_num') {
                            notParse = true;
                        }
                        if (!notParse) {
                            if (!item[key]) {
                                item[key] = 0;
                            } else {
                                item[key] = parseFloat(item[key]);
                            }
                            if (item[key] < 0) {
                                costsPeoplesFalse = true;
                            }
                        }
                    }
                    return item;
                });
            }
            const costsProducesFalse = false;
            if (newData.costsProduces.length>0) {
                newData.costsProduces = newData.costsProduces.map(item=>{
                    for (const key in item) {
                        let notParse = false;
                        if (key === 'rcp_id' || key === 'report_id'||key==='name'||key==='sort_num') {
                            notParse = true;
                        }
                        if (!notParse) {
                            if (!item[key]) {
                                item[key] = 0;
                            } else {
                                item[key] = parseFloat(item[key]);
                            }
                            if (item[key] < 0) {
                                costsProducesFalse = true;
                            }
                        }
                    }
                    return item;
                });
            }
            json.parseData = newData;
            if (costsFalse || costsDirectsFalse || costsDirectDetailsFalse || costsPeoplesFalse || costsProducesFalse) {
                json.isExistMinus = true;
            }
            return json;
        },
        // 获取本月混凝土销量
        getMonthSales() {
            return this.$axios.get('/interfaceApi/report/sales/concretesales/month/' + this.reportMonth.report_id);
        },
        // 计算直接成本
        async computDirect(type, rowData, costsDirect, directIndex, fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            if (type === 'detail') {
                // 单耗
                if (rowData.weight) {
                    if (this.monthSales ) {
                        if (this.monthSales !== 0) {
                            rowData.unitconsumption = util.toFixed6(parseFloat(rowData.weight) / (this.monthSales * 10000));
                        } else {
                            rowData.unitconsumption = 0;
                        }
                    } else {
                        const res = await this.getMonthSales();
                        if (res !== 0) {
                            this.monthSales = res;
                            rowData.unitconsumption = util.toFixed6(parseFloat(rowData.weight) / (this.monthSales * 10000));
                        } else {
                            rowData.unitconsumption = 0;
                        }
                    }
                } else {
                    rowData.unitconsumption = 0;
                }
                // 结算价格
                if (parseFloat(rowData.weight) && parseFloat(rowData.money)) {
                    rowData.settlementprice = util.toFixed6(parseFloat(rowData.money) / parseFloat(rowData.weight));
                } else {
                    rowData.settlementprice = 0;
                }
                costsDirect.weight = 0;
                costsDirect.money = 0;
                this.data.costsDirects[directIndex].costsDirectDetails.map(item => {
                    if (item.weight) {
                        costsDirect.weight = util.toFixed6(parseFloat(costsDirect.weight) + parseFloat(item.weight));
                    }
                    if (item.money) {
                        costsDirect.money = util.toFixed6(parseFloat(costsDirect.money) + parseFloat(item.money));
                    }
                });
                if (parseFloat(costsDirect.money) && parseFloat(costsDirect.weight)) {
                    costsDirect.settlementprice = util.toFixed6(parseFloat(costsDirect.money) / parseFloat(costsDirect.weight));
                } else {
                    costsDirect.settlementprice = 0;
                }
                if (costsDirect.weight) {
                    if (this.monthSales ) {
                        if (this.monthSales !== 0) {
                            costsDirect.unitconsumption = util.toFixed6(parseFloat(costsDirect.weight) / (this.monthSales * 10000));
                        } else {
                            costsDirect.unitconsumption = 0;
                        }
                    } else {
                        const res = await this.getMonthSales();
                        if (res > 0) {
                            this.monthSales = res;
                            costsDirect.unitconsumption = util.toFixed6(parseFloat(costsDirect.weight) / (this.monthSales * 10000));
                        } else {
                            costsDirect.unitconsumption = 0;
                        }
                    }
                } else {
                    costsDirect.unitconsumption = 0;
                }
                // 直接成本总计
                this.data.costs.direct_weight = 0;
                this.data.costs.direct_money = 0;
                this.data.costs.direct_unitconsumption = 0;
                this.data.costsDirects.map(item => {
                    if (item.costsDirect.weight) {
                        this.data.costs.direct_weight = util.toFixed6(parseFloat(this.data.costs.direct_weight)
                        + parseFloat(item.costsDirect.weight));
                    }
                    if (item.costsDirect.money) {
                        this.data.costs.direct_money = util.toFixed6(parseFloat(this.data.costs.direct_money) + parseFloat(item.costsDirect.money));
                    }
                });
                if (this.monthSales) {
                    if (!this.data.costs.direct_weight) {
                        this.data.costs.direct_weight = 0;
                    }
                    if (!this.data.costs.water_weight) {
                        this.data.costs.water_weight = 0;
                    }
                    this.data.costs.direct_unitconsumption = util.toFixed6((parseFloat(this.data.costs.direct_weight)
                    + parseFloat(this.data.costs.water_weight))
                    / (this.monthSales * 10000));
                    this.data.costs.direct_settlementprice = util.toFixed6(parseFloat(this.data.costs.direct_money) / (this.monthSales * 10000));
                } else {
                    const res = await this.getMonthSales();
                    if (res > 0) {
                        this.monthSales = res;
                        if (!this.data.costs.direct_weight) {
                            this.data.costs.direct_weight = 0;
                        }
                        if (!this.data.costs.water_weight) {
                            this.data.costs.water_weight = 0;
                        }
                        this.data.costs.direct_unitconsumption = util.toFixed6((parseFloat(this.data.costs.direct_weight)
                        + parseFloat(this.data.costs.water_weight))
                        / (this.monthSales * 10000));
                        this.data.costs.direct_settlementprice = util.toFixed6(parseFloat(this.data.costs.direct_money) / (this.monthSales * 10000));
                    } else {
                        this.data.costs.direct_unitconsumption = 0;
                        this.data.costs.direct_settlementprice = 0;
                    }
                }
            }
        },
        // eslint-disable-next-line max-params
        async computDirect2(type, rowData, costsDirect, directIndex, fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            if ( type === 'costsDirect') {
                // 单耗
                if (costsDirect.weight) {
                    if (this.monthSales ) {
                        if (this.monthSales !== 0) {
                            costsDirect.unitconsumption = util.toFixed6(parseFloat(costsDirect.weight) / (this.monthSales * 10000));
                        } else {
                            costsDirect.unitconsumption = 0;
                        }
                    } else {
                        const res = await this.getMonthSales();
                        if (res > 0) {
                            this.monthSales = res;
                            costsDirect.unitconsumption = util.toFixed6(parseFloat(costsDirect.weight) / (this.monthSales * 10000));
                        } else {
                            costsDirect.unitconsumption = 0;
                        }
                    }
                } else {
                    costsDirect.unitconsumption = 0;
                }
                // 结算价格
                if (parseFloat(costsDirect.money) && parseFloat(costsDirect.weight)) {
                    costsDirect.settlementprice = util.toFixed6(parseFloat(costsDirect.money) / parseFloat(costsDirect.weight));
                } else {
                    costsDirect.settlementprice = 0;
                }
            }
        },
        // 水
        async computWater(fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            if (this.monthSales ) {
                if (this.data.costs.water_weight) {
                    if (this.monthSales > 0) {
                        this.data.costs.water_unitconsumption = util.toFixed6(parseFloat(this.data.costs.water_weight) / (this.monthSales * 10000));
                    }
                } else {
                    this.data.costs.water_unitconsumption = 0;
                }
            } else {
                const res = await this.getMonthSales();
                if (res > 0) {
                    this.monthSales = res;
                    this.data.costs.water_unitconsumption = util.toFixed6(parseFloat(this.data.costs.water_weight) / (this.monthSales * 10000));
                } else {
                    this.data.costs.water_unitconsumption = 0;
                }
            }
            if (parseFloat(this.data.costs.water_money) && parseFloat(this.data.costs.water_weight)) {
                this.data.costs.water_settlementprice
                = util.toFixed6(parseFloat(this.data.costs.water_money) / parseFloat(this.data.costs.water_weight));
            } else {
                this.data.costs.water_settlementprice = 0;
            }
        },
        // 电力
        async computPower(fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            if (this.monthSales ) {
                if (this.data.costs.power_weight) {
                    if (this.monthSales > 0) {
                        this.data.costs.power_unitconsumption = util.toFixed6(parseFloat(this.data.costs.power_weight) / (this.monthSales * 10000));
                    }
                } else {
                    this.data.costs.power_unitconsumption = 0;
                }
            } else {
                const res = await this.getMonthSales();
                if (res > 0) {
                    this.monthSales = res;
                    this.data.costs.power_unitconsumption = util.toFixed6(parseFloat(this.data.costs.power_weight) / (this.monthSales * 10000));
                } else {
                    this.data.costs.power_unitconsumption = 0;
                }
            }
            if (parseFloat(this.data.costs.power_money) && parseFloat(this.data.costs.power_weight)) {
                this.data.costs.power_settlementprice
                = util.toFixed6(parseFloat(this.data.costs.power_money) / parseFloat(this.data.costs.power_weight));
            } else {
                this.data.costs.power_settlementprice = 0;
            }
        },
        // 直接人工
        async computPeoples(item, fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            this.data.costs.person_money = 0;
            if (this.monthSales ) {
                if (!item.money) {
                    item.money = 0;
                }
                item.price = util.toFixed6(parseFloat(item.money) / (this.monthSales * 10000));
                this.data.costsPeoples.map(item => {
                    if (item.money) {
                        this.data.costs.person_money = util.toFixed6(parseFloat(this.data.costs.person_money) + parseFloat(item.money));
                    }
                });
                if (this.data.costs.person_money) {
                    this.data.costs.person_price = util.toFixed6(parseFloat(this.data.costs.person_money) / (this.monthSales * 10000));
                } else {
                    this.data.costs.person_price = 0;
                }
            } else {
                const res = await this.getMonthSales();
                if (res > 0) {
                    this.monthSales = res;
                    if (!item.money) {
                        item.money = 0;
                    }
                    item.price = util.toFixed6(parseFloat(item.money) / (this.monthSales * 10000));
                    this.data.costsPeoples.map(item => {
                        if (item.money) {
                            this.data.costs.person_money = util.toFixed6(parseFloat(this.data.costs.person_money) + parseFloat(item.money));
                        }
                    });
                    if (this.data.costs.person_money) {
                        this.data.costs.person_price = util.toFixed6(parseFloat(this.data.costs.person_money) / (this.monthSales * 10000));
                    }
                } else {
                    item.price = 0;
                    this.data.costs.person_price = 0;
                }

            }
        },
        // 制造成本
        async computProduce(item, fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
            this.data.costs.produce_meney = 0;
            if (this.monthSales) {
                if (!item.money) {
                    item.money = 0;
                }
                item.price = util.toFixed6(parseFloat(item.money) / (this.monthSales * 10000));
                this.data.costsProduces.map(item => {
                    if (item.money) {
                        this.data.costs.produce_meney = util.toFixed6(parseFloat(this.data.costs.produce_meney) + parseFloat(item.money));
                    }
                });
                this.data.costs.produce_price = util.toFixed6(parseFloat(this.data.costs.produce_meney) / (this.monthSales * 10000));
            } else {
                const res = await this.getMonthSales();
                if (res !== 0) {
                    this.monthSales = res;
                    if (!item.money) {
                        item.money = 0;
                    }
                    item.price = util.toFixed6(parseFloat(item.money) / (this.monthSales * 10000));
                    this.data.costsProduces.map(item => {
                        if (item.money) {
                            this.data.costs.produce_meney = util.toFixed6(parseFloat(this.data.costs.produce_meney) + parseFloat(item.money));
                        }
                    });
                    this.data.costs.produce_price = util.toFixed6(parseFloat(this.data.costs.produce_meney) / (this.monthSales * 10000));
                } else {
                    item.price = 0;
                    this.data.costs.produce_price = 0;
                }
            }
        },
    },
    created() {
        this.getCosts();
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.wrapper{}
</style>